import React, { useRef,useState  } from 'react';
import QuotationForm from './components/QuotationForm/QuotationForm';
import generatePDF, { Resolution, Margin, Options } from 'react-to-pdf';
import './App.css';

const options: Options = {
  filename: `lanha-baogia-${new Date().toLocaleDateString()}.pdf`,
  method: 'save',
  resolution: Resolution.HIGH,
  page: {
    margin: Margin.NONE,
    format: 'a4',
    orientation: 'landscape',
    pageBreakBefore: true,
  },
};

function App() {
  const pdfRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSave,setIsSave] = useState(false);
  const [isPasswordFormVisible, setIsPasswordFormVisible] = useState(false);
  const [enteredId, setEnteredId] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');

  var printOptions = {
    pages: 'all',             // In tất cả các trang
    layout: 'landscape',      // Chiều ngang
    background: true,         // Bao gồm đồ họa nền
    color: true,              // Màu sắc
    paperSize: 'A4',      // Kích thước giấy letter
    pagesPerSheet: 1,         // 1 trang trên mỗi tờ giấy
    marginsType: 1,           // Mặc định
    scale: 1                  // Mặc định
};

function printPage() {
  if (isFormValid) {
    var printOptions = {
      pages: 'all',        // In tất cả các trang
      layout: 'landscape', // Chiều ngang
      paperSize: 'A4'      // Kích thước giấy A4
    };

    // Mở cửa sổ in và cài đặt
    window.printOptions = printOptions;

    // Sử dụng window.print() để in
    window.print(window.printOptions = printOptions);
  } else {
    alert("Vui lòng điền đầy đủ thông tin trước khi xuất PDF.");
  }
  
};
function handleSaveData(){
  setIsPasswordFormVisible(true);
};
function resetIsSave() {
  setEnteredId('');
  setIsSave(false);
}

const handlePasswordSubmit = () => {
  if (enteredPassword === 'lanha123') {
    setIsSave(enteredId);
    setIsPasswordFormVisible(false);
  } else {
    alert('Mật khẩu không đúng.');
  }
};
const closeForm = () => {
  setIsPasswordFormVisible(false);
};

return (
  <div className="App">
    <div ref={pdfRef} className="page">
      <style>
        {`
          @page {
            size: A4 landscape;
          }
        `}
      </style>
      <QuotationForm yonFormValidationChange={setIsFormValid}  resetIsSave={resetIsSave} isSave = {isSave}/>
    </div>
    {isPasswordFormVisible ? (
      <div className="password-form">
        <button className="close-button" onClick={closeForm}>x</button>
        <h3>Nhập ID và Mật khẩu</h3>
        <input
          type="text"
          placeholder="ID"
          value={enteredId}
          onChange={(e) => setEnteredId(e.target.value)}
        />
        <input
          type="password"
          placeholder="Mật khẩu"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
        />
        <button onClick={handlePasswordSubmit}>OK</button>
      </div>
    ) : null}
    <div
      style={{
        position: 'relative',
      }}
    >
      <button
        className="no-print"
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          zIndex: 1,
          background: '#ff9c04',
          color: 'white',
          borderRadius: '20px',
          padding: '10px 20px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Xuất PDF
      </button>
      <button
        onClick={handleSaveData}
        className="no-print"
        style={{
          position: 'fixed',
          bottom: '50px', // Điều chỉnh vị trí theo ý muốn
          right: '10px',
          zIndex: 1,
          background: '#5cb85c',
          color: 'white',
          borderRadius: '20px',
          padding: '10px 20px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Lưu
      </button>
    </div>
  </div>
);
}


export default App;
